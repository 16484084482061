<app-alerts *ngIf="bDisplayErrorBlock" [resMessage]="resMessage" (bDisplayErrorBlockChange)=" this.bDisplayErrorBlock = $event"></app-alerts>
<nav class="navbar navbar-expand-lg bg-body-tertiary w-100 d-flex justify-content-between px-3">
  <div class="d-flex gap-4 align-items-center">
    <div *ngIf="userDetails.role !== 'editor'" class="" >
      <i class="fa-solid fa-bars hamburger cursor ms-1 text-white me-4" (click)="toggleCollapse()"></i>
      <span>
        <img class="tkf-logo cursor-pointer" src="../../../../assets/images/logo.png" alt="" routerLink="/admin">
      </span>
    </div>
  </div>
  <div class="">
      <div class="">

        <div ngbDropdown class="d-inline-block ">
          <div class="profile-dropdown" ngbDropdownToggle>
            <img  class="profile-width-height object-fit-cover ms-3 cursor" [src]="userProfileImage" (error)="loadAvatar()"/>
          </div>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-avatar dropdown-menu-profile">
            <div class="d-flex justify-content-start align-items-center gap-2 p-2 border-bottom">
              <img class="profile-width-height" [src]="userProfileImage" alt="">
              <div >
                <h6 class="fs-6 mb-0 word-break">{{ userDetails?.firstName | titlecase }}</h6>
                <span class="fw-light fs-6" *ngIf="userDetails?.isSuperAdmin != 1">{{ userDetails?.role =='user' ? 'Participant' : userDetails?.role == 'editor' ? 'Volunteer' : userDetails?.role | titlecase }}</span>
                <span class="fw-light fs-6" *ngIf="userDetails?.isSuperAdmin == 1">Super Admin</span>
              </div>
            </div>
            <button ngbDropdownItem class="px-3 " (click)="editProfile(editAProfile)" > <i class="fa-regular fa-pen-to-square me-2"></i> Edit Profile</button>
            <button ngbDropdownItem class="px-3 " (click)="changePasswordModal(changeAPassword)"> <i class="fa-solid fa-light fa-key me-2"></i> Change Password</button>
            <button ngbDropdownItem class="px-3 " (click)="logout()"> <i class="fa-solid fa-right-from-bracket me-2"></i> Logout</button>
          </div>
      </div>

    </div>
  </div>
</nav>

<ng-template #editAProfile let-modal>
<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">Edit Profile</h5>
</div>
<div class="modal-body py-4">
    <div class=" ">
      <form [formGroup]="updateProfileForm">
        <div class="">
          <div class="">
            <div class="d-flex justify-content-center">
              <div class="profile-avatar">
                <img *ngIf="!userProfileImage" class="separate-width-height object-fit-cover" [src]="userProfileImage"
                (error)="loadAvatar()" alt="Profile Picture" />
                <img *ngIf="userProfileImage" class="separate-width-height object-fit-cover"
                [src]="userProfileImage" (error)="loadAvatar()" alt="Profile Picture" />
                <span class="edit-profile-img d-flex align-items-center justify-content-center">
                  <div class="upload-btn-wrapper">
                    <input class="d-none" id="uploadfile" accept=".jpeg,.jpg,.png" type="file" name="myfile" (change)="onFileSelect($event)"/>
                    <label for="uploadfile"><i class="fa-solid fa-pen cursor text-white"></i></label>
                  </div>
                </span>
              </div>
            </div>
          </div>
        <div>
            <div class="form-group mb-3">
                <label for="" class="form-label">First Name</label>
                <input type="text" class="form-control" placeholder="First Name" formControlName="firstName">
                <small *ngIf="updateProfileForm.controls['firstName'].hasError('required') && updateProfileForm.controls['firstName'].touched"
                  class="text-danger">First Name is required</small>
                <small *ngIf="updateProfileForm.controls['firstName'].hasError('pattern')" class="text-danger">
                 First character cannot be space.
                </small>
                <small *ngIf="(!updateProfileForm.controls['firstName'].hasError('required') || updateProfileForm.controls['firstName'].touched) &&
               !updateProfileForm.controls['firstName'].hasError('pattern')  && (updateProfileForm.get('firstName')?.value?.length ==1 || updateProfileForm.get('firstName')?.value?.length>=14)" class="text-danger font-14">
                  First Name must be between 2-15 characters.</small>
            </div>
            
            <div class="form-group mb-3 ">
                <label for="" class="form-label">Last Name</label>
                <input type="text" class="form-control " formControlName="lastName" placeholder="Last Name">
                <small *ngIf="updateProfileForm.controls['lastName'].hasError('required') && updateProfileForm.controls['lastName'].touched"
                class="text-danger">Last Name is required</small>
                <small *ngIf="updateProfileForm.controls['lastName'].hasError('pattern')" class="text-danger">
                 First character cannot be space.
                </small>
                <small *ngIf="(!updateProfileForm.controls['lastName'].hasError('required') || updateProfileForm.controls['lastName'].touched) &&
               !updateProfileForm.controls['lastName'].hasError('pattern')  && (updateProfileForm.get('lastName')?.value?.length ==1 || updateProfileForm.get('lastName')?.value?.length>=14)" class="text-danger font-14">
                  Last Name must be between 2-15 characters.</small>
            </div>
          <!-- this phone field is just for admin purpose -->
            <div class="form-group" *ngIf="userDetails?.isSuperAdmin != 1">
              <label class="form-label" for="name">Phone</label>
              <div class="d-flex border custom-border">
                <div appClickOutside (clickOutside)="displayCountries=false"
                  class="position-relative d-flex align-items-center">
                  <div (click)="displayCountriesDropdown()">
                    <img class="flag" [src]="selectedCountry" />
                    <i class="fa-regular cursor-pointer" [ngClass]="displayCountries ? 'fa-chevron-up':'fa-chevron-down'"></i>
                  </div>
                  <div class="country-dropdown" *ngIf="displayCountries">
                    <div *ngFor="let item of cities" class="city-item" (click)="chooseCountry(item)">
                      <span class="country-name">{{item.name}}</span>
                      <span>{{'('+ item.code + ')'}}</span>
                    </div>
                  </div>
                </div>
                <div class="flex-fill">
                  <input type="text" class="form-control phone-input newuser-input" placeholder="(012) 123-1234" formControlName="mobilePhone"
                    [inputMask]="selectedMask" />
                </div>
              </div>
              <small *ngIf="updateProfileForm.controls['mobilePhone'].hasError('required') &&
                      updateProfileForm.controls['mobilePhone'].touched" class="text-danger support-text">
                      Phone number is required
              </small>
              <small  class="text-danger support-text" *ngIf="updateProfileForm.get('mobilePhone').hasError('pattern')">
                      Invalid phone number.
              </small>
            </div>
        </div>
        </div>
      </form>
    </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary me-2" (click)="dismiss()">
    Cancel
  </button>
  <button type="button" class="btn btn-primary" [disabled]="loader" (click)="submitProfileSettingData()">
    Submit
  </button>
</div>
</ng-template>



<ng-template #changeAPassword let-modal>
	<app-loader *ngIf="loader"></app-loader>
	<div class="modal-header">
		<h4 class="modal-title modal-header-font">Change Password</h4>
	</div>
	<div class="modal-body">
		<div class="form-row">
			<label class="form-label" for="Userid">User Id</label>
			<form [formGroup]="resetPasswordForm">
				<div class="input-group input-group-lg col-md-12 mb-3">
					<input type="text" class="form-control  bg-light" id="Userid" placeholder="User Id" formControlName="userId" 
						readonly/>
				</div>
				<label class="form-label" for="Currentpassword">Current Password</label>
				<div class="input-group input-group-lg col-md-12">
          <div class="position-relative passwordIcon">
					<input class="form-control custom-input" id="Currentpassword" placeholder="Current Password"
						formControlName="oldPassword"  [type]="currentPasswordVisible ? 'text' : 'password'" />
            <span (click)="currentPasswordVisibility()" class="field-icon toggle-password" [ngClass]="!currentPasswordVisible ? 'fa-regular fa-eye-slash': 'fa fa-fw fa-eye '"></span>            
          </div>
          </div>
				<div class="mb-3">
					<small
						*ngIf="resetPasswordForm.controls['oldPassword'].hasError('required') && resetPasswordForm.controls['oldPassword'].touched"
						class="text-danger">Current password is required</small>
				</div>
				<label class="form-label" for="Newpassword">New Password</label>
				<div class="input-group input-group-lg col-md-12">
          <div class="position-relative passwordIcon">
					<input class="form-control custom-input" id="Newpassword" placeholder="New Password"
						formControlName="password" [type]="passwordVisible ? 'text' : 'password'" />
            <span (click)="passwordVisibility()" class="field-icon toggle-password" [ngClass]="!passwordVisible ? 'fa-regular fa-eye-slash': 'fa fa-fw fa-eye '"></span>            
          </div>
          </div>
				<div class="mb-3">
					<small
						*ngIf="resetPasswordForm.controls['password'].hasError('required') && resetPasswordForm.controls['password'].touched"
						class="text-danger">New password is required</small>
					<small
						*ngIf="resetPasswordForm.controls['password'].hasError('pattern') && resetPasswordForm.controls['password'].touched"
						class="text-danger">Password needs to be at least 8 characters long.</small>
						<small
						*ngIf="resetPasswordForm.get('password').hasError('maxlength') && resetPasswordForm.get('password').touched"
						class="text-danger">  Password cannot exceed 24 characters.</small>
				</div>
				<label class="form-label" for="Repeatpassword">Confirm New Password</label>
				<div class="input-group input-group-lg col-md-12">
          <div class="position-relative passwordIcon">
					<input class="form-control custom-input" id="Repeatpassword" placeholder="Confirm New Password"
						formControlName="rptPassword" [type]="newPasswordVisible ? 'text' : 'password'"/>
            <span (click)="newPasswordVisibility()" class="field-icon toggle-password" [ngClass]="!newPasswordVisible ? 'fa-regular fa-eye-slash': 'fa fa-fw fa-eye '"></span>
          </div>
          </div>
				<div class="mb-3">
					<small
						*ngIf="resetPasswordForm.controls['rptPassword'].hasError('required') && resetPasswordForm.controls['rptPassword'].touched"
						class="text-danger">Confirm new password is required</small>
					<small
						*ngIf="resetPasswordForm.controls['rptPassword'].errors?.['mustMatch'] && resetPasswordForm.controls['rptPassword'].dirty"
						class="text-danger">Password and confirm new password not matched</small>
				</div>
			</form>
		</div>
	</div>

	<div class="modal-footer">
		<div class="d-flex justify-content-end align-items-center">
      <button type="button" class="btn btn-secondary me-2" (click)=" dismiss()">Cancel</button>
			<button type="button" class="btn btn-primary" [disabled]="loader" (click)="changePassword()">Submit</button>
		</div>
	</div>
</ng-template>