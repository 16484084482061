import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environments';
import { SessionService } from './services/session.service';
import { Keepalive } from '@ng-idle/keepalive';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { BrowserCompatibilityService } from './services/browser-compatibility.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'TexasKidneyFoundation';
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = undefined;
  bOldBrowser: boolean;
  oBroswerNameVersion: { browserName: string; browserVersion: number; };
  resMessage: { message: any, responseType: any, color: any };
  bDisplayErrorBlock: boolean;

  constructor(
    private oCheckIdleState: Idle,
    private keepalive: Keepalive,
    private oRouter: Router,
    private oSessionService: SessionService,
    private oBroswerVersionService: BrowserCompatibilityService,
    private modalService:NgbModal
  ) {
    this.initializeBrowserInfo();
    this.configureIdleState();
    this.setupIdleStateEventListeners();
    this.resetTimer();
  }

  ngOnInit() {
    // Read 'rememberMe' directly from localStorage
    const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
    // Set the timeout based on 'rememberMe'
    const timeoutDuration = rememberMe ? 604800 : 14400;  
    this.setupKeepalive(timeoutDuration);
  }

  initializeBrowserInfo() {
    this.bOldBrowser = this.oBroswerVersionService.isOldBrowser();
    this.oBroswerNameVersion = this.oBroswerVersionService.GetBrowserNameAndVersion();
  }

  configureIdleState() {
    this.oCheckIdleState.setIdle(5);  // For testing purposes, set idle time to 5 seconds
    this.oCheckIdleState.setTimeout(1800);  // Set timeout period to 30 minutes
    this.oCheckIdleState.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  }

  setupIdleStateEventListeners() {
    this.oCheckIdleState.onIdleEnd.subscribe(() => this.handleIdleEnd());
    this.oCheckIdleState.onTimeout.subscribe(() => this.handleTimeout());
    this.oCheckIdleState.onIdleStart.subscribe(() => this.handleIdleStart());
    this.oCheckIdleState.onTimeoutWarning.subscribe(countdown => this.handleTimeoutWarning(countdown));
  }

  setupKeepalive(oIncomingTime) {
    this.keepalive.interval(5);
    this.keepalive.onPing.subscribe(() => {
      let TimeOutForApplication = oIncomingTime;      
      let rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
      rememberMe == true ? TimeOutForApplication = 604800 : TimeOutForApplication = 14400;
      this.lastPing = new Date();
      this.oSessionService.cSessionService_setSessionExpiry();
    });
  }

  handleIdleEnd() {
    this.idleState = 'No longer idle.';
    console.log('On idle end Idle state:', this.idleState);
  }

  handleTimeout() {
    this.idleState = 'Timed out!';
    this.timedOut = true;
    console.log('On Time out Idle state:', this.idleState);
    this.clearLocalStorageAndNavigate();
  }

  handleIdleStart() {
    this.idleState = 'You\'ve gone idle!';
    console.log('On idle start Idle state:', this.idleState);
  }

  handleTimeoutWarning(countdown: number) {
    this.idleState = `Your application will log out in ${countdown} seconds!`;
    if(countdown < 11)
    {
      this.displayAlertMessage(this.idleState, 'error', 'danger');
    }
  }

  clearLocalStorageAndNavigate() {
    localStorage.clear();
    this.oRouter.navigateByUrl('/authentication/login');
    this.modalService.dismissAll()
  }

  resetTimer() {
    this.checkSessionExpiry();
    this.oCheckIdleState.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
    console.log('AppComponent_ResetTimer');
  }

  checkSessionExpiry() {
    const oDate = new Date();
    const nSessionExpiryTime = this.oSessionService.cSessionService_getSessionExpiry();
    const nCurrentTime = oDate.getTime();

    if (nSessionExpiryTime !== null && nCurrentTime > nSessionExpiryTime) {
      localStorage.removeItem(`ls${environment.APP_NAME}SessionExpiryTime`);
      localStorage.clear();
      this.modalService.dismissAll()
      console.log('Session_CheckSessionExpiry: Current Session is expired. So, logging out');
      this.oRouter.navigateByUrl('/authentication/login');
    }
  }
  displayAlertMessage(sIncommingMessage, sIncommingResponseType, sIncommingColor) {
    this.bDisplayErrorBlock = true
    this.resMessage =
    {
      message: sIncommingMessage,
      responseType: sIncommingResponseType,
      color: sIncommingColor
    };
    setTimeout(() => { this.bDisplayErrorBlock = false; }, 10000);
  }
}
