const env = window["env"] || {};

export const environment = {
  production: env.production || false,
  APP_NAME: env.APP_NAME || 'Six21-Two21',
  Title: env.Title || 'Six21 - Two21',
  BaseURL: env.BaseURL || 'https://api.tkf-six21two21.trilloapps.com',
  AccessToken: env.AccessToken || 'lsAccessToken',
  ImagesURL: env.ImagesURL || '',
  Theme: env.Theme || 'styles.scss',
  PollingInterval: env.PollingInterval || 0,

   firebaseConfig: {
    apiKey: env.apiKey || "AIzaSyD8fmZGPofEgkT30_d-OJ5486JYC6qyqnY",
    authDomain: env.authDomain || "totemic-atom-408917.firebaseapp.com",
    projectId: env.projectId || "totemic-atom-408917",
    storageBucket: env.storageBucket || "totemic-atom-408917.appspot.com",
    messagingSenderId: env.messagingSenderId || "417819781636",
    appId: env.appId || "1:417819781636:web:30a45f30e33790ec39a351",
    measurementId: env.measurementId || "G-8RVS9J7W3K",
    vapidKey: env.vapidKey || "BE-yi7pY6mTRiDBk0GwhWUM99pH2to1dlzDIBzI6YJwMKpsiEJ9mz7nNU090xTIMH4D0CoFYX4jaRcoerjuM51E"

  }
};
