import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { userData, adminData } from "./nav-data";
import { SessionService } from 'src/app/services/session.service';
import { filter } from 'rxjs';
interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}
interface sideBarItems {
  routerLink: string;
  icon: string;
  label: string;
}
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  @Input() collapsed:any;
  @Output() onToggleSideNav: EventEmitter<SideNavToggle> = new EventEmitter();
  screenWidth: number =0;
  navData: sideBarItems[] = [];
  currentUrl: any;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth
    if (this.screenWidth <= 992) {
      this.collapsed = false;
      this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
    }
    if (this.screenWidth >= 992) {
      this.collapsed = true;
      this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
    }
  }

  constructor( private sessionService: SessionService,private router: Router ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.currentUrl = event.url;
    });

    this.currentUrl = window.location.pathname;
   }
  ngOnInit(): void {
    let sideBarNavItems:sideBarItems[];
    let userDetails = this.sessionService.SessionService_GetUserDetails();
    if (userDetails.role == 'admin') {
      sideBarNavItems = JSON.parse (JSON.stringify(adminData))
    }
    else if (userDetails.role == 'user') {
      sideBarNavItems = JSON.parse (JSON.stringify(userData))
    }
      this.navData = sideBarNavItems;
    }
    toggleCollapse() {
      this.collapsed = !this.collapsed;
      this.onToggleSideNav.emit({
        collapsed: this.collapsed,
        screenWidth: this.screenWidth,
      });
    }
  
    closeSidenav() {
      this.collapsed = false;
      this.onToggleSideNav.emit({
        collapsed: this.collapsed,
        screenWidth: this.screenWidth,
      });
    }

    onSidebarClick(link){
      if(link!==this.currentUrl){
    localStorage.removeItem('currentPage')
  }  
    }

    isLinkActive(itemRouterLink: string): boolean {
            return (
        (this.currentUrl == '/admin' && itemRouterLink == '/admin') ||
        (this.currentUrl == '/admin/participants' && itemRouterLink == '/admin/participants') ||
        (this.currentUrl == '/admin/volunteers' && itemRouterLink == '/admin/volunteers') ||
        (this.currentUrl.startsWith('/usermanager') && itemRouterLink.startsWith('/usermanager')) || 
        (this.currentUrl.startsWith('/survey') && itemRouterLink.startsWith('/survey')) || 
        (this.currentUrl.startsWith('/event') && itemRouterLink.startsWith('/event')) ||
        (this.currentUrl.startsWith('/demographic-info') && itemRouterLink.startsWith('/demographic-info'))
      );
    }
}
