import { Component, EventEmitter, HostListener, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionService } from 'src/app/services/session.service';
import { DataService } from "src/app/services/data.service";
import { MustMatch } from 'src/app/services/must-match.validator';
import { Location } from '@angular/common';
import { createMask } from '@ngneat/input-mask';

interface SideNavToggle {
  collapsed: boolean;
  screenWidth: number;
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  userProfileImage: any;
  screenWidth: number = 0;
  @Input() toggleSidebar: any;
  @Output() onToggleSidenav: EventEmitter<SideNavToggle> = new EventEmitter();
  collapsed: boolean = true;
  userDetails: any;
  filesToUpload: any = [];
  file: File | null;
  filename: string | null;
  resMessage: { message: any, responseType: any, color: any };
  bDisplayErrorBlock: boolean;
  resetPasswordForm: FormGroup;
  updateProfileForm: FormGroup;
  loader: boolean = false;
  passwordVisible:boolean=false;
  newPasswordVisible:boolean=false;
  currentPasswordVisible:boolean=false;
  signedUrl: any;
  userProfileImageUrl: string;
  selectedItem : any;
  selectedCountry: string = "../../../../assets/images/united-states.png";
  selectedCode: string = "+1";
  selectedMask: any = createMask('999-999[-9999]');
  displayCountries: any;

  cities = [
    { id: 1, name: 'USA', code: '+1', src: '../../../../assets/images/united-states.png', mask: createMask('999-999[-9999]') },
    { id: 3, name: 'Pakistan', code: '+92', src: '../../../../assets/images/pakistan-flag.png', mask: createMask('999-9999999') },
  ];

  chooseCountry(incommingObj) {
    this.selectedCountry = incommingObj.src;
    this.selectedCode = incommingObj.code
    this.displayCountries = false
    this.selectedMask = incommingObj.mask;
  }
  displayCountriesDropdown() {
    setTimeout(() => {
      this.displayCountries = !this.displayCountries
    }, 100);
  }

  formatPhoneNumber(phoneNumber: string): string {
    const cleanedNumber = phoneNumber.replace(/-/g, '');
    return `${this.selectedCode}${cleanedNumber}`;
  }

  constructor(private modalService: NgbModal, private oRoute: Router, private sessionService: SessionService, private dataService: DataService, private formBuilder: FormBuilder, private location: Location) { }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth
    if (this.screenWidth <= 992) {
      this.collapsed = false;
      this.onToggleSidenav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
    }
    if (this.screenWidth >= 992) {
      this.collapsed = true;
      this.onToggleSidenav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
    }
  }

  ngOnInit() {
    this.location.onUrlChange((url: string) => {
      // This callback is called when the user navigates backward or forward.
      this.dismiss()
    });
    this.userDetails = this.sessionService.SessionService_GetUserDetails();
    this.GetUserProfile();
    this.ResetPasswordFormControls();
    this.updateProfileFormControls();
    this.resetUpdateProfileForm();
  }
  ngOnDestroy(): void {
    this.modalService.dismissAll()

  }


  // ***************************************************** Apis  **********************************************//


  uploadFiles() {
    for (const file of this.filesToUpload) {
      const formData: FormData = new FormData();
      formData.append("file", file);
      formData.append("folder", "public/profiles");
      formData.append("makePublic", "true");
      this.dataService.uploadImage(formData).subscribe({
        next: (response) => {
          this.userProfileImageUrl = response.folderName + '/' + response.fileName;          
          this.RetieveSignedUrl(response);
        },
        error: (error) => {
          console.error(error)
        },
        complete: () => { },
      });
    }
  }

  RetieveSignedUrl(response) {
    let body = {
      "asAttachment": false,
      "fileName": response.fileName,
      "folderId": -1,
      folderName: "public/profiles",
      "id": response?.id,
      "method": "GET"
    };
    
    this.dataService.RetieveSignedUrl(body).subscribe({
      next: (response) => {
        this.userProfileImage = response?.data?.signedUrl;     
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => { },
    });
  }
  

  GetUserProfile() {
    const body = {
      id: this.userDetails.id.toString()
    };
    this.dataService.GetUserProfile(body).subscribe({
      next: (result) => {
        if (result.status == 'failed') {
          this.displayAlertMessage(result.message, 'error', 'danger');
        } else {
          this.userProfileImage = result.data?.pictureUrl
          this.dataService.profilePicture.next(this.userProfileImage)
          this.userDetails['isSuperAdmin'] = result.data?.isSuperAdmin ?? 0;
        }
      },
      error: (error) => {
        this.displayAlertMessage(error.detailMessage, 'error', 'danger');
      },
    });
  }

  changePassword() {
    this.loader = true;
    if (this.resetPasswordForm.valid) {
      let body = this.userDetails
      body.oldPassword = this.resetPasswordForm.controls['oldPassword'].value
      body.password = this.resetPasswordForm.controls['password'].value
      body.rptPassword = this.resetPasswordForm.controls['rptPassword'].value
      this.dataService.ChangePassword(body).subscribe({
        next: (result) => {
          this.loader = false;
          if (result.failed) {
            this.displayAlertMessage('Current Password is Wrong!', 'error', 'danger');
          }
          else {
            this.modalService.dismissAll();
            this.ResetPasswordFormControls();
            this.displayAlertMessage('Change Password Successfully', 'success', 'success');
            this.currentPasswordVisible = false;
            this.newPasswordVisible = false;
            this.passwordVisible = false;
          }
        },
        error: (error) => {
          this.loader = false;
          this.displayAlertMessage('Current Password is Wrong!', 'error', 'danger');

        },
        complete: () => { },
      }
      )
    }
    else {
      this.loader = false;
      this.resetPasswordForm.markAllAsTouched()
    }
  }

  submitProfileSettingData() {
    this.loader = true;
    if (this.updateProfileForm.valid) {
      this.updateProfileForm.patchValue({
        pictureUrl: this.userProfileImageUrl,
      });
      const formattedPhoneNumber = this.formatPhoneNumber(this.updateProfileForm.value.mobilePhone);  
      this.updateProfileForm.value.mobilePhone=formattedPhoneNumber
      this.dataService.EditUserDetail(this.updateProfileForm?.value).subscribe({
        next: (result) => {
        this.loader = false;
          if (result.status === 'failed') {
            this.displayAlertMessage(result.message, 'failed', 'danger')
          } else {
            this.displayAlertMessage(result.message, 'success', 'success');
            console.log(this.userDetails['firstName']);
            this.userDetails['firstName'] = this.updateProfileForm.get('firstName').value;
            this.userDetails.lastName = this.updateProfileForm.get('lastName').value;
            this.userDetails.mobilePhone = this.updateProfileForm.get('mobilePhone').value;
            this.sessionService.SessionService_SetUserDetails(this.userDetails);
            this.GetUserProfile();
            this.dismiss()
          }
        },
        error: (error) => {
          this.loader = false;
          console.error(" ERROR ===>>", error);
          this.displayAlertMessage(error.message, 'danger', 'danger')
        },
      });
    }
    else {
      this.loader = false;
      this.updateProfileForm.markAllAsTouched();
    }
  }


  // ***************************************************** Modals  **********************************************//

  editProfile(content) {
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        windowClass: "custom-class",
        centered: true,
        size: "md",
        backdrop: 'static'
      })
  }

  changePasswordModal(content) {
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        windowClass: "custom-class",
        centered: true,
        size: "md",
        backdrop: 'static'
      })
      this.ResetPasswordFormControls();
  }

  // ***************************************************** Functions  **********************************************//

  onFileSelect(event) {
    const selectedFiles: FileList = event.target.files as FileList;
    const validFileTypes = ["image/png", "image/jpeg", "image/jpg"];
    const invalidFiles = Array.from(selectedFiles).filter(
      (file) => !validFileTypes.includes(file.type)
    );
    this.filesToUpload = event.target.files;
    this.uploadFiles();
    this.filename = "";
    if (event.target.files && event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.filename = this.file.name;
    }
  }

  dismiss() {
    this.modalService.dismissAll();
    this.resetUpdateProfileForm();
    this.ResetPasswordFormControls()
    this.currentPasswordVisible = false;
    this.newPasswordVisible = false;
    this.passwordVisible = false;
  }


  logout() {
    localStorage.clear();
    this.oRoute.navigateByUrl('/authentication/login');
  }

  toggleCollapse() {
    this.collapsed = !this.collapsed;
    this.onToggleSidenav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth });
  }

  loadAvatar() {
    this.userProfileImage = '../../../assets/images/profile.jpeg'
  }

  updateProfileFormControls() {
    let oTextRejex = /^(?! )(.*)$/;
    this.updateProfileForm = this.formBuilder.group({
      firstName: [this.userDetails.firstName, [Validators.required, Validators.pattern(oTextRejex), Validators.minLength(2), Validators.maxLength(14)]],
      lastName: [this.userDetails.lastName, [Validators.required, Validators.pattern(oTextRejex), Validators.minLength(2), Validators.maxLength(14)]],
      mobilePhone: [this.userDetails?.mobilePhone, [Validators.required,Validators.pattern(/^\d{3}-?\d{3}-?\d{4}$/)]],
      userId: this.userDetails?.userId,
      pictureUrl:[''],
      id: this.userDetails?.id
    });
  }

  ResetPasswordFormControls() {
    const oPasswordRejex = /^.{8,}$/;
    const maxPasswordLength = 24;
    this.resetPasswordForm = this.formBuilder.group({
      userId: [this.userDetails.userId],
      emailVerifyKey: [''],
      oldPassword: ['', Validators.required],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(oPasswordRejex),
          Validators.maxLength(maxPasswordLength)
        ]
      ],
      rptPassword: ['', Validators.required],
      orgName: ['']
    },
      { validator: MustMatch('password', 'rptPassword') });
  }
  resetUpdateProfileForm() {
    const mobilePhoneWithoutCode = this.userDetails?.mobilePhone?.replace(/^(\+1|\+92|\+52)/, '');
    this.updateProfileForm.reset({
      id: this.userDetails.id.toString(),
      firstName: this.userDetails.firstName,
      lastName: this.userDetails.lastName,
      mobilePhone: mobilePhoneWithoutCode,
      userId: this.userDetails.userId,
    });
    const selectedCountry = this.cities.find(city => city.code === this.userDetails?.mobilePhone?.substring(0, 3));
    if (selectedCountry) {
      const { src, code, mask } = selectedCountry;
      this.selectedCountry = src;
      this.selectedCode = code;
      this.selectedMask = mask;
    }
  }

  removeLeadingSpaces(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    let inputValue = inputElement.value;
    inputValue = inputValue.replace(/^\s+/, "");
    inputElement.value = inputValue;
  }

  handleKeyPress(event: KeyboardEvent) {
    if (event.key === ' ' && (event.target as HTMLInputElement).selectionStart === 0) {
      event.preventDefault();
    }
  }

  currentPasswordVisibility() {
    this.currentPasswordVisible = !this.currentPasswordVisible;
  }

  passwordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  newPasswordVisibility() {
    this.newPasswordVisible = !this.newPasswordVisible;
  }

  // ***************************************************** ALERT MESSAGES  **********************************************//

  displayAlertMessage(sIncommingMessage, sIncommingResponseType, sIncommingColor) {
    this.bDisplayErrorBlock = true
    this.resMessage =
    {
      message: sIncommingMessage,
      responseType: sIncommingResponseType,
      color: sIncommingColor
    };
    setTimeout(() => { this.bDisplayErrorBlock = false; }, 5000);
  }
}


