import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { AlertsComponent } from './alerts/alerts.component';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ClickOutsideDirective } from 'src/app/directive/click-outside.directive';
import { LoaderComponent } from './loader/loader.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgSelectModule } from '@ng-select/ng-select'; // Import NgSelectModule
import { CustomPaginationComponent } from './custom-pagination/custom-pagination.component';
import { InputMaskModule } from '@ngneat/input-mask';


@NgModule({
  declarations: [
    HeaderComponent,
    AlertsComponent,
    SidebarComponent,
    ClickOutsideDirective,
    LoaderComponent,
    CustomPaginationComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    BsDatepickerModule.forRoot(),
    InputMaskModule,

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [HeaderComponent,SidebarComponent,AlertsComponent,ClickOutsideDirective, LoaderComponent,NgbPaginationModule, NgSelectModule, CustomPaginationComponent]
})
export class SharedModule { }



