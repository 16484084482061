import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent {
  @Input() resMessage;
  @Output() bDisplayErrorBlockChange = new EventEmitter<boolean>(); // Output event

  closeAlert(){
    this.bDisplayErrorBlockChange.emit(false);

  }

}
