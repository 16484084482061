import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environments';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor() {}

  // set access token
  SessionService_SetAccessToken(oData: any) {
    localStorage.setItem(environment.AccessToken, oData);
  }
  // get access token
  SessionService_GetAccessToken() {
    return localStorage.getItem(environment.AccessToken);
  }
  // set user details
  SessionService_SetUserDetails(oData: any) {
    localStorage.setItem('lsUserDetails', JSON.stringify(oData));
  }
  // get user details
  SessionService_GetUserDetails() {
    return JSON.parse(localStorage.getItem('lsUserDetails'));
  }
  SessionService_SetSignupDetails(oData: any) {
    localStorage.setItem('signupDetails', JSON.stringify(oData));
  }
  // get user details
  SessionService_GetSignupDetails() {
    return JSON.parse(localStorage.getItem('signupDetails'));
  }

  //set and get current page in local storage
  SessionService_SetCurrentPage(oData) {
    localStorage.setItem('currentPage', JSON.stringify(oData));
  }
  SessionService_GetCurrentPage() {
    return JSON.parse(localStorage.getItem('currentPage'));
  }

      //***********Storing and getting Current Page  */
      SessionService_SetPageSize(oData: any) {
        localStorage.setItem('pageSize', JSON.stringify(oData))
      }
      SessionService_GetPageSize() {
        return JSON.parse(localStorage.getItem('pageSize'));
      }


   //set and get current page in local storage
   SessionService_SetStudyId(oData) {
    localStorage.setItem('StudyId', JSON.stringify(oData));
  }
  SessionService_GetStudyId() {
    return JSON.parse(localStorage.getItem('StudyId'));
  }

  //set session expiry
  cSessionService_setSessionExpiry() {
    const nSessionExpiryTime: number = new Date().getTime() + 1800000;
    localStorage.setItem("ls" + environment.APP_NAME + "SessionExpiryTime", JSON.stringify(nSessionExpiryTime));
  }
  //get session expiry
  cSessionService_getSessionExpiry() {
    return JSON.parse(localStorage.getItem("ls" + environment.APP_NAME + "SessionExpiryTime"));
  }
   
  //set and get current page in local storage
   SessionService_SetViewStudyDetails(oData) {
    localStorage.setItem('StudyDetails', JSON.stringify(oData));
  }
  SessionService_GetViewStudyDetails() {
    return JSON.parse(localStorage.getItem('StudyDetails'));
  }
  // set and get joinedStudy Details
  SessionService_SetJoinedStudyDetails(oData) {
    localStorage.setItem('JoinedStudyDetails', JSON.stringify(oData));
  }
  SessionService_GetJoinedStudyDetails() {
    return JSON.parse(localStorage.getItem('JoinedStudyDetails'));
  }
  
}
