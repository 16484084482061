import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { canActivateTeam } from './guards/auth.guard';
import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { canActivateRole } from './guards/userrole.guard';

const routes: Routes =
[
 	{
		path: '',
		component : BlankComponent,
		children: [
      { path: '', redirectTo: '/authentication/login', pathMatch: 'full' },
			{
				path: 'authentication',
				loadChildren:
					() => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)
			},
      {
        path: "",
        redirectTo: "/app/dashboard",
        pathMatch: "full",
      },
		]
  	},
	  {
		path: '',
		component: FullComponent,canActivate: [canActivateTeam],
		children:
		[
      {
        path: "admin", loadChildren: () => import("./modules/admin/admin.module").then((m) => m.AdminModule),
		canActivate: [canActivateRole],
		data: { allowedRoles: ['admin'] }
      },
	  {
        path: "usermanager", loadChildren: () => import("./modules/usermanager/usermanager.module").then((m) => m.UsermanagerModule),
		canActivate: [canActivateRole],
		data: { allowedRoles: ['admin'] }
      },
	  {
        path: "survey", loadChildren: () => import("./modules/surveymanagement/surveymanagement.module").then((m) => m.SurveymanagementModule),
		canActivate: [canActivateRole],
		data: { allowedRoles: ['admin'] }
      },
	  {
        path: "event", loadChildren: () => import("./modules/eventmanagement/eventmanagement.module").then((m) => m.EventmanagementModule),
		canActivate: [canActivateRole],
		data: { allowedRoles: ['admin'] }
      },
	  {
        path: "demographic-info", loadChildren: () => import("./modules/demographic-Information/demographic-info.module").then((m) => m.DemographicInfoModule),
		canActivate: [canActivateRole],
		data: { allowedRoles: ['admin'] }
      },
		]
	  },
  	{ path: '**', redirectTo: "authentication/login", pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
