<div class="sidenav" [ngClass]="collapsed ? 'sidenav-collapsed':''">
    <ul class="sidenav-nav p-0">
        <li class="sidenav-nav-item" *ngFor="let item of navData">
            <a class="sidenav-nav-link px-2 " [routerLink]="item.routerLink" 
            [class.active]="isLinkActive(item.routerLink)"     
            (click)="onSidebarClick(item.routerLink)" 
            >
                <i class="sidenav-link-icon sidebar-icons-new fa-solid" [class]="item.icon" ></i>
                <span class="sidenav-link-text" *ngIf="collapsed">{{item.label}}</span>
            </a>
        </li>
    </ul>
</div>