import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environments';


@Injectable({
  providedIn: 'root'
})
export class DataService {

constructor(private http: HttpClient) { }
profilePicture = new BehaviorSubject<string>("");

//************* forgotPassword API *********************//

forgotPassword(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL+'/_preauthsvc/user/forgotPassword',body)
}

//************* changePassword API *********************//
ChangePassword(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/_service/um/changeMyPassword', body);
}
//************* resetPassword API *********************//
resetPassword(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL +  '/_preauthsvc/user/resetPassword', body);
}

uploadImage(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/upload', body);
}
//************* Edit User profile API *********************//

editUserProfile(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/upload', body);
}
EditUserDetail(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/EditUserDetail', body);
}

//************* Get User Details *********************//

getUserDetails(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetUserProfile', body);
}

//**************** Setting User Profile API's *************************//

GetUserProfile(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetUserProfile', body);
}
//**************** Set UserProfile  *************************//

SetUserProfile(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/EditBasicDetails', body);
}

  //************* User Manager API starts */
  GetUsersList(body): Observable<any> {
    // return this.http.post<any>(environment.BaseURL + '/ds/page/auth/vault', body);
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetUsers', body);
  }

  DeleteUser(id : any): Observable<any> {
    return this.http.delete<any>(environment.BaseURL + '/_service/um/deleteUser'+"?id="+ id);
  }

  CreateUser(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/newUser', body);
  }

  EditUser(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/editUser', body);
  }
  ResetPassword(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/resetPassword', body);
  }

  AddSurvey(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/AddSurvey', body);
  }

  GetSurveys(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetSurveys', body);
  }

  GetSurveyDetail(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetSurveyDetail', body);
  }

  DeleteSurvey(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DeleteSurvey', body);
  }

  EditSurveyDetail(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/EditSurveyDetail', body);
  }

  GetSurveyResponses(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetSurveyResponses', body);
  }

  GetSurveyParticipants(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetSurveyParticipants', body);
  }

  GetEvents(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetEvents', body);
  }

  GetLocations(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetLocations', body);
  }

  GetVolunteers(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetVolunteers', body);
  }

  AddEvent(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/AddEvent', body);
  }

  EditEventDetail(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/EditEventDetail', body);
  }

  DeleteEvent(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DeleteEvent', body);
  }


  GetEventDetail(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetEventDetail', body);
  }

  GetStations(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetStations', body);
  }

  GetEventVolunteers(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetEventVolunteers', body);
  }

  GetEventStations(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetEventStations', body);
  }

  AddEventStation(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/AddEventStation', body);
  }
  
  DeleteEventStation(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DeleteEventStation', body);
  }
  
  EditEventStationDetail(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/EditEventStationDetail', body);
  }

  RetieveSignedUrl(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/folder/retieveSignedUrl', body);
  }

  GetDashboardTickets(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetDashboardTickets', body);
  }
  
  
  GetHHFormSectionDetail(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetHHFormSectionDetail', body);
  }

  GetParticipantFollowUps(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetParticipantFollowUps', body);
  }

  AddLocation(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/AddLocation', body);
  }
  
  GetEventParticipants(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetEventParticipants', body);
  }

  GetHHFormSectionFields(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetHHFormSectionFields', body);
  }

  EditHHFormSectionField(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/EditHHFormSectionField', body);
  }

  GetDashboardEvents(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetDashboardEvents', body);
  }

  GetDashboardSurvey(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetDashboardSurvey', body);
  }

  GetSurveyResults(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetSurveyResults', body);
  }

  AddHHFormSectionField(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/AddHHFormSectionField', body);
  }

  DeleteHHFormSectionField(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DeleteHHFormSectionField', body);
  }

  ExportSurveyResponses(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportSurveyResponses', body);
  }

  EditHHFormSectionFields(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/EditHHFormSectionFields', body);
  }

}


