import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { SessionService } from '../services/session.service';


const canActivate = (route: ActivatedRouteSnapshot,state: RouterStateSnapshot) => 
{
  const sessionService = inject(SessionService);
  const userDetail= sessionService.SessionService_GetUserDetails();
  const allowedRoles = route?.data?.['allowedRoles'] as string[];
   if(userDetail?.role && allowedRoles?.includes(userDetail?.role)) 
    {
      return true;
    } 
    else
    {
      history.back();
      return false;
    }
};
export const canActivateRole: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {return canActivate(route, state);}
