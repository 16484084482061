export const userData = [
  {
    routerLink: "/participant",
    icon: "fa-light fa-user",
    label: "Participant",
  },
  {
    routerLink: "/participant/medical-history",
    icon: "fa-light fa-files-medical",
    label: "Demographic Information",
  },
];

export const adminData = [
  {
    routerLink: "/admin",
    icon: "fa-sharp fa-light fa-house",
    label: "Dashboard",
  },
  {
    routerLink: "/event",
    icon: "fa-light fa-calendar-lines",
    label: "Event Management",
  },
  {
    routerLink: "/survey",
    icon: "fa-light fa-memo-pad",
    label: "Survey Management",
  },
  {
    routerLink: "/demographic-info",
    icon: "fa-light fa-file-medical",
    label: "Demographic Information",
  },
  {
    routerLink: "/usermanager",
    icon: "fa-light fa-users",
    label: "User Manager",
  },
];
