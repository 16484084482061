import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
interface SideNavToggle{
  screenWidth: number;
  collapsed: boolean;
}

interface UserDetails {
  id: number;
  createdAt: number;
  updatedAt: number;
  deleted: boolean;
  userId: string;
  firstName: string;
  lastName: string;
  role: string;
  orgName: string;
  emailVerified: boolean;
  tenantId: string;
  termsAgreed: boolean;
  privacyAgreed: boolean;
  mobilePhone: string;
  authTenantId: string;
  fullName: string;
  uid: string;
}
@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {
  userDetails: UserDetails;

  constructor(private sessionService : SessionService) { }
  isSideNavCollapsed = true;
  screenWidth: number = 0;

  onToggleSideNav(data: SideNavToggle): void {
    this.isSideNavCollapsed = data.collapsed;
  }
 
  ngOnInit(): void {
   this.userDetails =  this.sessionService.SessionService_GetUserDetails()
  }

}